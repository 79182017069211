import React from "react";
import md5 from "md5";
import { BaseModal } from "./BaseModal";
import { PrivacyPolicy } from "./privacyPolicy";
import { submitData, dispatchShowMessageEvent } from "../Header";

export class SignUp extends BaseModal {
    constructor(props) {
        super(props);

        this._privacyPolicyModal = React.createRef();

        this._usernameInput = React.createRef();
        this._emailInput = React.createRef();
        this._passwordInput = React.createRef();
    }

    onSubmit = async (e) => {
        e.preventDefault();

        let pass = md5(this._passwordInput.current.value);

        let formData = {
            username: this._usernameInput.current.value,
            email: this._emailInput.current.value,
            password: pass,
        };

        let data = await submitData('register', formData)

        if (!data.succeed) {
            let inputField = null;
            switch (data.data.invalidField) {
                case "username":
                    inputField = this._usernameInput;
                    break;
                case "email":
                    inputField = this._emailInput;
                    break;
                default:
                    dispatchShowMessageEvent("something went wrong, please contact us!", true);
                    break
            }

            inputField.current.setCustomValidity(data.note);
            inputField.current.reportValidity();
        } else {
            dispatchShowMessageEvent("Register Succeed, Please check your email");
        }
    }

    onShowSignIn = (e) => {
        this.props.setShowSignIn();
    }

    onShowPrivacyPolicy = (e) => {
        this._privacyPolicyModal.current.showModal();
    }

    onKeyUpUserName = (e) => {
        e.target.setCustomValidity("");
    }

    onKeyUpEmail = (e) => {
        e.target.setCustomValidity("");
    }

    onKeyUpRegisterPassword = (e) => {
        e.target.setCustomValidity("");

        let registerPasswordCheckDom = document.getElementById('register_password_check');
        e.target.setCustomValidity('');
        registerPasswordCheckDom.setCustomValidity('');

        // let number = /([0-9])/;
        // let alphabets = /([a-zA-Z])/;
        // let special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
        let password_val = e.target.value;

        if (password_val.length >= 8) {
            if (registerPasswordCheckDom.value !== "" &&
                e.target.value !== registerPasswordCheckDom.value) {
                e.target.setCustomValidity("password not match!");
            }
            else {
                e.target.setCustomValidity("");
            }

            return;
        }

        e.target.setCustomValidity('should be at least 8 characters');
    }

    onKeyUpRegisterPasswordCheck = (e) => {
        e.target.setCustomValidity("");

        let registerPasswordDom = document.getElementById('register_password');
        registerPasswordDom.setCustomValidity('');
        e.target.setCustomValidity('');

        if (registerPasswordDom.value !== e.target.value)
            e.target.setCustomValidity("password not match!");
        else
            e.target.setCustomValidity("");
    }

    render = () => {
        let displayMode = 'block';
        if (!this.state.showModal)
            displayMode = 'none';

        return (
            <div>
                <div className="modal modalbackground" id="registerModal" tabIndex="-1"
                    role="dialog" aria-labelledby="registerModal" aria-hidden="true"
                    style={{ display: displayMode }}>

                    <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                        <div className="container h-100">
                            <div
                                className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                                    <div className="card" style={{ borderRadius: '15px' }}>
                                        <div className="card-body p-5">
                                            <div className="row">
                                                <div className="col-11"></div>
                                                <div className="col-1">
                                                    <button id="registerModal_close" type="button" className="btn-close" onClick={this.closeModal}></button>
                                                </div>
                                            </div>
                                            <div style={{ height: '70vh', overflowY: 'auto' }}>
                                                <h2 className="text-uppercase text-center mb-5">Create an account</h2>
                                                <form id="register_form" onSubmit={this.onSubmit}>
                                                    <div className="form-outline mb-4">
                                                        <input type="text" id="register_username"
                                                            className="form-control form-control-lg" maxLength="50"
                                                            onKeyUp={this.onKeyUpUserName}
                                                            ref={this._usernameInput} required />

                                                        <label className="form-label" htmlFor="register_username">UserName</label>
                                                    </div>
                                                    <div className="form-outline mb-4">
                                                        <input type="email" id="register_email"
                                                            className="form-control form-control-lg" maxLength="255"
                                                            onKeyUp={this.onKeyUpEmail}
                                                            ref={this._emailInput} required />

                                                        <label className="form-label" htmlFor="register_email">Email</label>
                                                    </div>
                                                    <div className="form-outline mb-4 form-group">
                                                        <input type="password" id="register_password"
                                                            className="form-control form-control-lg"
                                                            maxLength="50"
                                                            onKeyUp={this.onKeyUpRegisterPassword}
                                                            ref={this._passwordInput} required />

                                                        <label className="form-label" htmlFor="register_password">Password</label>
                                                    </div>
                                                    <div className="form-outline mb-4">
                                                        <input type="password" id="register_password_check"
                                                            className="form-control form-control-lg"
                                                            maxLength="50" onKeyUp={this.onKeyUpRegisterPasswordCheck} required />

                                                        <label className="form-label" htmlFor="register_password_check">Repeat
                                                            your password</label>
                                                    </div>
                                                    <div className="container">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault"
                                                                style={{ boxHhadow: '0 0 0 1px #fff, 0 0 0 3px #0275d8' }} required />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                I have read and agree <a href="# " onClick={this.onShowPrivacyPolicy}>Privacy Policy </a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="d-flex justify-content-center">
                                                        <button type="submit"
                                                            className="btn btn-primary btn-block btn-lg gradient-custom-4 text-body">Register</button>
                                                    </div>
                                                    <p className="text-center text-muted mt-5 mb-0">
                                                        Have already an account?
                                                        <a href="# " className="fw-bold text-body" onClick={this.onShowSignIn}>
                                                            <u>Login here</u>
                                                        </a>
                                                    </p>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <PrivacyPolicy ref={this._privacyPolicyModal} />
            </div>
        );
    }
}