import { BaseModal } from "./BaseModal";

export class CookiePolicy extends BaseModal {
    // componentDidUpdate = (prevProps, prevState, snapshot) => {
    //     if (prevProps.showModal != this.props.showModal)
    //         this.setState({ showModal: this.props.showModal });
    // }

    render = () => {
        if (!this.state.showModal)
            return "";

        return (
            <div className="modal modalbackground" id="cookiePolicyModal" tabIndex="-1"
                role="dialog" aria-labelledby="cookiePolicyModal" aria-hidden="true"
                style={{ display: "block" }}>

                <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                    <div className="container h-100">
                        <div
                            className="row d-flex justify-content-center align-items-center h-100">

                            <div className="col-12 col-md-9 col-lg-7 col-xl-6" style={{ width: '100%' }}>
                                <div className="card" style={{ borderRadius: '15px' }}>

                                    <div className="card-body p-5">
                                        <div className="row">
                                            <div className="col-11"></div>
                                            <div className="col-1">
                                                <button id="cookiePolicyModal_close" type="button"
                                                    className="btn-close" onClick={this.closeModal}></button>
                                            </div>
                                        </div>

                                        <div style={{ height: '60vh', overflowY: 'auto' }}>

                                            <h1>Cookie Policy for RocketServer</h1>

                                            <p>This is the Cookie Policy for RocketServer, accessible
                                                from https://rocketserverengine.com/</p>

                                            <p>
                                                <strong>What Are Cookies</strong>
                                            </p>

                                            <p>As is common practice with almost all professional
                                                websites this site uses cookies, which are tiny files that are
                                                downloaded to your computer, to improve your experience. This
                                                page describes what information they gather, how we use it and
                                                why we sometimes need to store these cookies. We will also
                                                share how you can prevent these cookies from being stored
                                                however this may downgrade or 'break' certain elements of the
                                                sites functionality.</p>

                                            <p>
                                                <strong>How We Use Cookies</strong>
                                            </p>

                                            <p>We use cookies for a variety of reasons detailed below.
                                                Unfortunately in most cases there are no industry standard
                                                options for disabling cookies without completely disabling the
                                                functionality and features they add to this site. It is
                                                recommended that you leave on all cookies if you are not sure
                                                whether you need them or not in case they are used to provide a
                                                service that you use.</p>

                                            <p>
                                                <strong>Disabling Cookies</strong>
                                            </p>

                                            <p>You can prevent the setting of cookies by adjusting the
                                                settings on your browser (see your browser Help for how to do
                                                this). Be aware that disabling cookies will affect the
                                                functionality of this and many other websites that you visit.
                                                Disabling cookies will usually result in also disabling certain
                                                functionality and features of the this site. Therefore it is
                                                recommended that you do not disable cookies.</p>
                                            <p>
                                                <strong>The Cookies We Set</strong>
                                            </p>

                                            <ul>
                                                <li>
                                                    <p>Login related cookies</p>
                                                    <p>We use cookies when you are logged in so that we can
                                                        remember this fact. This prevents you from having to log in
                                                        every single time you visit a new page. These cookies are
                                                        typically removed or cleared when you log out to ensure that
                                                        you can only access restricted features and areas when logged
                                                        in.</p>
                                                </li>
                                            </ul>

                                            <p>
                                                <strong>More Information</strong>
                                            </p>

                                            <p>if you are still looking for more information then you
                                                can contact us through one of our preferred contact methods:</p>

                                            <ul>
                                                <li>Email: rocketserverteam@gmail.com</li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}