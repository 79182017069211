import { Component } from "react";

export class ErrorPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errmsg: '',
        }
    }

    showError(text) {
        this.setState({
            errmsg: text,
        });
    }

    render = () => {
        if(this.state.errmsg === '')
            return '';
            
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <p className="fs-3"> <span className="text-danger">Opps!</span> something went wrong.</p>
                    <p className="lead">
                        {this.state.errmsg}
                    </p>
                    <a href="." className="btn btn-primary">Go Home</a>
                </div>
            </div>
        );
    }
}