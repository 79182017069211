import { Component } from "react";

export class BaseModal extends Component{
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };
    }

    closeModal = (e) => {
        this.setState({ showModal: false });
    }

    showModal = ()=>{
        this.setState({ showModal: true });
    }
}