import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { connect } from "react-redux";
import { mapStateToProps } from './reducers/reducer';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from './components/Header'
import { Main } from './components/Main';
import { CookiePolicy } from './components/modals/CookiePolicy';
import { allActions } from "./reducers/reducer";
import { Tutorial } from './components/Tutorial';
import { Verification } from './components/Verification';
import { ErrorPage } from './components/ErrorPage';
import { listenShowErrorEvent, listenShowMessageEvent } from './components/Header';
import { ResetPassword } from './components/modals/ResetPassword';
import { Message } from './components/modals/Message'

class App extends Component {
  constructor(props) {
    super(props);

    this._headerRef = React.createRef();
    this._cookieRef = React.createRef();
    this._errorPageRef = React.createRef();
    this._messageModal = React.createRef();
  }

  componentDidMount = () => {
    this.addScript('resources/mdb5/js/mdb.min.js');
    this.addScript('resources/assets/js/main.js');

    let userName = Cookies.get('username');
    if (userName && userName !== '') {
      this.props.dispatch(allActions.setUserAction(userName));
      // console.log('foud cookie username=', userName);
    } else {
      // console.log('not foud cookie username=', userName);
    }

    document.getElementById('btn_show_cookie').onclick = () => {
      this._cookieRef.current.showModal();
    }

    listenShowErrorEvent((e) => {
      this._errorPageRef.current.showError(e.detail.errorText);
    });

    listenShowMessageEvent((e) => {
      this._messageModal.current.showMessage(e.detail.messageText, e.detail.error);
    });

    let preloader = document.getElementById('preloader');
    if (preloader) {
      setTimeout(() => {
        preloader.remove()
      }, 300);
    }
  }

  addScript = (scriptSrc) => {
    const script = document.createElement('script');
    script.setAttribute('src', scriptSrc);
    document.head.appendChild(script);
  }

  onShowDownload = () => {
    this._headerRef.current.onShowDownload();
  }

  render = () => {
    return (
      <div className="App">
        <BrowserRouter>
          <Header urlMain={process.env.REACT_APP_URL_PATH} ref={this._headerRef} />

          <Routes>
            <Route path={process.env.REACT_APP_URL_PATH} element={<Main onShowDownload={this.onShowDownload} />} />
            <Route path={process.env.REACT_APP_URL_PATH + "verify"} element={<Verification />} />
            <Route path={process.env.REACT_APP_URL_PATH + "passwordreset"} element={<ResetPassword />} />
            <Route path={process.env.REACT_APP_URL_PATH + "tutorial"} element={<Tutorial />} />
          </Routes>
        </BrowserRouter>

        <ErrorPage ref={this._errorPageRef} />
        <CookiePolicy ref={this._cookieRef} />
        <Message ref={this._messageModal} />
      </div>
    );
  };
}

export default connect(mapStateToProps)(App);