import { Component } from "react";

export class LoadingSpinner extends Component {
    constructor(props){
        super(props);
        this.state = {
            display:'none',
        }
    }

    show = () =>{
        this.setState({display:'block'});
    }

    hide = () =>{
        this.setState({display:'none'});
    }

    render = () => {
        let displayMode = this.state.display;
        
        return (
            <div id="loadingSpinner" style={{ display: displayMode }}></div>
        );
    }
}