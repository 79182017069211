export function setUserAction(userName) {
    return {
        type: "SETUSER",
        value: userName
    };
};

export function clearUserAction() {
    return {
        type: "CLEARUSER"
    };
};

export function userReducer(state = "", action) {
    switch (action.type) {
        case "SETUSER":
            return {
                ...state,
                value: action.value
            };
        case "CLEARUSER":
            return {
                ...state,
                value: ""
            };
        default:
            return state;
    }
};