import { Component } from "react";

export class Product extends Component {
    render = () => {
        return (
            <section id="product" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Product</h2>
                    </div>

                    <div className="row content">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-5">

                            <br />
                            Rocket Server is a high performance webscoket server,
                            <br />
                            the core socket server is written in native C++  to achieve the high performance,
                            support both on Ubuntu and Windows operation system
                            <br />
                            the engine core is use C++ for implementation, multi-threaded for network IO access.
                            <br />
                            <ul>
                                <li><i className="ri-check-double-line"></i> Easy to use</li>
                                <li><i className="ri-check-double-line"></i> High performance</li>
                                <li><i className="ri-check-double-line"></i> Zero setup or installation, only download and unzip the file</li>
                            </ul>
                        </div>
                        <div className="col-lg-5">
                            <img src="resources/assets/img/server-architecture.png" className="img-fluid animated" alt="" />
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}