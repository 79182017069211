import React, { Component } from 'react';
import { connect } from "react-redux";
import { mapStateToProps } from '../reducers/reducer';
import { Navigation } from '../components/Navigation';

const ConnectNavigation = connect(mapStateToProps, null, null, { forwardRef: true })(Navigation)

export function redirectToHome(){
    window.location.replace(process.env.REACT_APP_URL_PATH);
}

export async function fetchData(path, postBody) {
    postBody.origin = window.origin;

    dispatchShowLoadingEvent(true);

    let res = await fetch(process.env.REACT_APP_URL_API + path, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        dataType: "json",
        encode: true,
        body: JSON.stringify(postBody),
    });

    let data = await res.json();
    if (data.succeed)
        dispatchCloseAllModalEvent();

    dispatchShowLoadingEvent(false);
    return data;
}

export async function submitData(path, formBody) {
    dispatchShowLoadingEvent(true);

    formBody.origin = window.origin + process.env.REACT_APP_SUBMIT_ORIGIN;

    const formData = new FormData();

    Object.keys(formBody).forEach((key) => {
        formData.append(key, formBody[key]);
    });

    let res = await fetch(process.env.REACT_APP_URL_API + path, {
        method: 'POST',
        body: formData,
    });

    let data = await res.json();
    if (data.succeed)
        dispatchCloseAllModalEvent();

    dispatchShowLoadingEvent(false);
    return data;
}

export function listenShowLoadingEvent(listener) {
    document.addEventListener("showLoading", listener);
}

export function listenShowMessageEvent(listener) {
    document.addEventListener("showMessage", listener);
}

export function listenCloseAllModalEvent(listener) {
    document.addEventListener("closeAllModal", listener);
}

export function listenShowErrorEvent(listener) {
    document.addEventListener("showError", listener);
}

export function dispatchShowLoadingEvent(showLoading) {
    const event = new CustomEvent("showLoading", { detail: { showLoading: showLoading } });
    document.dispatchEvent(event);
}

export function dispatchShowMessageEvent(messageText, error) {
    const event = new CustomEvent("showMessage", { detail: { messageText: messageText, error: error } });
    document.dispatchEvent(event);
}

export function dispatchCloseAllModalEvent() {
    const event = new CustomEvent("closeAllModal", { detail: {} });
    document.dispatchEvent(event);
}

export function dispatchShowErrorEvent(errorText) {
    const event = new CustomEvent("showError", { detail: { errorText: errorText } });
    document.dispatchEvent(event);
}

export class Header extends Component {
    constructor(props) {
        super(props);
        this._navigationRef = React.createRef();
    }

    onShowDownload = () => {
        this._navigationRef.current.showDownload();
    }

    render = () => {
        return (
            <header id="header" className="fixed-top ">
                <div className="container d-flex align-items-center">
                    <h1 className="logo me-auto">
                        <a href={this.props.urlMain}>Rocket Server</a>
                    </h1>

                    <ConnectNavigation ref={this._navigationRef} />
                </div>
            </header>
        );
    }
}