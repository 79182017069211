import { combineReducers } from "redux";
import { userReducer, setUserAction, clearUserAction } from "./user"

const rootReducer = combineReducers({
    userReducer,
});

const allActions = {
    setUserAction,
    clearUserAction
};

const mapStateToProps = (state) => {
    const userReducer = state.userReducer;
    return {
        userReducer,
    };
}


export { rootReducer, allActions, mapStateToProps };