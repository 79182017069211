import { Component } from "react";

export class Contact extends Component {
    render = () => {
        return (
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Contact</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 d-flex align-items-stretch">
                            <div className="info">
                                <div className="email">
                                    <i className="bi bi-envelope"></i>
                                    <h4>Email:</h4>
                                    <p>rocketserverteam@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>

                </div>
            </section>
        );
    }
}