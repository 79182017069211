import { BaseModal } from "./BaseModal";

export class Download extends BaseModal {
    downloadEngine = (e, type, fileName) => {
        e.preventDefault();

        let path = process.env.REACT_APP_ENGINE_DOWNLOAD_PATH;
        if (window.location.protocol === 'https:') 
            path = path.replaceAll("http:", "https:")

        let file = path + type + "/" + fileName;
        
        fetch(file)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;

                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);

                document.body.removeChild(a);
            })
            .catch();
    }

    showLicense = (e) => {
        this.props.setShowLicense();
    }

    showSignIn = (e) => {
        this.props.setShowSignIn();
    }

    render = () => {
        let displayMode = 'block';
        if (!this.state.showModal)
            displayMode = 'none';

        let onClickShowLicenseOrSignIn = this.showSignIn;

        if (this.props.userName !== '')
            onClickShowLicenseOrSignIn = this.showLicense;

        return (
            <div className="modal modalbackground" id="downloadModal" tabIndex="-1" role="dialog" aria-labelledby="downloadModal"
                aria-hidden="true"
                style={{ display: displayMode }}>

                <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                    <div className="container h-100">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-9 col-lg-7 col-xl-6" style={{ width: '100%' }}>
                                <div className="card" style={{ borderRadius: '15px' }}>
                                    <div className="card-body p-5">
                                        <div className="row">
                                            <div className="col-11"></div>
                                            <div className="col-1">
                                                <button id="downloadModal_close" type="button" className="btn-close" onClick={this.closeModal}></button>
                                            </div>
                                        </div>

                                        <h2 className="text-uppercase text-center" style={{ marginBottom: '10px' }}>Download</h2>

                                        <div className="row" style={{ marginBottom: '20px' }}>
                                            <div className="panel-body table-responsive">
                                                <h5 className="text-uppercase">Server With Examples</h5>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td><a href="# "> <i className='i bi-download' style={{ fontSize: '25px' }} onClick={(e) => this.downloadEngine(e, 'server', 'RocketServer.tar.gz')}></i></a></td>
                                                            <td>Ubuntu</td>
                                                            <td>RocketServer.tar.gz</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a href="# "> <i className='i bi-download' style={{ fontSize: '25px' }} onClick={(e) => this.downloadEngine(e, 'server', 'RocketServer.zip')}></i></a></td>
                                                            <td>Windows</td>
                                                            <td>RocketServer.zip</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: '20px' }}>
                                            <div className="panel-body table-responsive">
                                                <h5 className="text-uppercase">Client With Example</h5>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td><a href="# "> <i className='i bi-download' style={{ fontSize: '25px' }} onClick={(e) => this.downloadEngine(e, 'client', 'ExampleFishGame.zip')}></i></a></td>
                                                            <td>Cocos Creator</td>
                                                            <td>ExampleFishGame.zip</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a href="# "> <i className='i bi-download' style={{ fontSize: '25px' }} onClick={(e) => this.downloadEngine(e, 'client', 'ExampleRawMessage.zip')}></i></a></td>
                                                            <td>Raw Javascript</td>
                                                            <td>ExampleRawMessage.zip</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="panel-body table-responsive">
                                                <h5 className="text-uppercase">StressTest Tool</h5>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td><a href="# "> <i className='i bi-download' style={{ fontSize: '25px' }} onClick={(e) => this.downloadEngine(e, 'robot', 'RocketRobot.tar.gz')}></i></a></td>
                                                            <td>Ubuntu</td>
                                                            <td>RocketRobot.tar.gz</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a href="# "> <i className='i bi-download' style={{ fontSize: '25px' }} onClick={(e) => this.downloadEngine(e, 'robot', 'RocketRobot.zip')}></i></a></td>
                                                            <td>Windows</td>
                                                            <td>RocketRobot.zip</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                        <p className="text-center text-muted mt-5 mb-0">
                                            <a href="# " className="fw-bold text-body" onClick={onClickShowLicenseOrSignIn} >
                                                <u>Don't forget to get your license here!</u>
                                            </a>;
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}