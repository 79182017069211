import { Component } from "react";
import { submitData } from "./Header";
import { dispatchShowErrorEvent, redirectToHome } from "./Header";

export class Verification extends Component {
    componentDidMount = async () => {
        const params = new URLSearchParams(window.location.search);

        let token = params.get("token");
        let formData = { token: token };
        let data = await submitData('verify', formData);

        if (!data.succeed) {
            console.log('verfdfd');
            dispatchShowErrorEvent(data.note);
            return;
        }

        redirectToHome();
    }

    render = () => {
        return (
            <div></div>
        );
    }
}