import { Component } from "react";

export class Tutorial extends Component {
    render = () => {
        return (
            <div className="container" style={{ paddingTop: '150px' }}>
                <p>Rocket Server is a lightweight, high performance Websocket Server, now support for NodeJS!.</p>
                <p>The core socket server is written in native C++ to achieve the high performance, support both on Ubuntu and Windows operation system</p>
                <h1 id="contents">Contents</h1>
                <hr />
                <ul>
                    <li><a href="#download">Download and install</a></li>
                    <li><a href="#get-license">Get License</a></li>
                    <li><a href="#usage">Usage</a> <br /> <br /></li>
                </ul>
                <h1 id="download">Download</h1>
                <hr />
                <h3 id="server">Server</h3>
                <ul>
                    <li><p>Support for both Windows and Ubuntu version, note that Ubuntu version can also work on other linux distribution, <strong><em>but might need to install GNU C Library (glibc) since the core engine is build from C++.</em></strong></p>
                    </li>
                    <li><p>after download and unzip the file, just need to install NodeJS by following steps, we recommand installing version 18 or later.</p>
                        <ol>
                            <li><p><code>sudo apt update &amp;&amp; sudo apt upgrade</code></p>
                            </li>
                            <li><p><code>curl -fsSL https://deb.nodesource.com/setup_18.x | sudo -E bash -</code></p>
                            </li>
                            <li><p><code>sudo apt-get install nodejs</code></p>
                            </li>
                            <li><p>cd to sever root directory then type <code>npm i</code> for install dependencies</p>
                            </li>
                        </ol>
                    </li>
                    <li><p>There are two examples below, can also find the demo link on Live Demo of top bar.</p>
                        <ol>
                            <li><p><code>node example_simplemessage.js</code> this is a simple message transmission between server and client, we recommend starting learn from this will be easier.</p>
                            </li>
                            <li><p><code>node example_fishgame.js</code> this is a shooting game, the client is made by Cocos Creator game engine, mainly show the load of concurrent user and latency.</p>
                            </li>
                        </ol>
                    </li>
                </ul>
                <h3 id="client">Client</h3>
                <ul>
                    <li>There are two examples of client corresponding to server examples<ol>
                        <li><code>ExampleFishGame</code> this is a shooting game made by Cocos Creator game engine, corresponding to the server example <code>node example_fishgame.js</code></li>
                        <li><code>ExampleRawMessage</code> this is the simple message client corresponding to the server example <code>node example_simplemessage.js</code></li>
                    </ol>
                    </li>
                </ul>
                <h3 id="stresstest-tool">StressTest tool</h3>
                <ul>
                    <li><p>The StressTest tool is also support for both Windows and Ubuntu version and run on NodeJS environment, the Ubuntu version can also work on other linux distribution, but like the server binary, <strong><em>might need to install GNU C Library (glibc) since the core is build from C++.</em></strong>, the StressTest tool also required the NodeJS installation, please take a look at steps on server side.</p>
                    </li>
                    <li><p>There are two examples below</p>
                        <ol>
                            <li><code>node example_simplemessage.js</code> this is the client example corresponding to server <code>example_simplemessage.js</code></li>
                            <li><code>node example_fishgame.js</code> this is the  client example corresponding to server <code>example_fishgame.js</code></li>
                        </ol>
                    </li>
                </ul>
                <p><br />
                    <br /></p>
                <h1 id="get-license">Get License</h1>
                <hr />
                <ul>
                    <li>The Server engine need verify the license to run, so just signin to get license, after get the license file (license.dat), <strong><em>put it in root directory of server side </em></strong></li>
                </ul>
                <p><br />
                    <br /></p>
                <h1 id="usage">Usage</h1>
                <hr />
                <h3 id="server">Server</h3>

                <div className="container" style={{ paddingLeft: '2%' }}>
                    <p><code>import {'{'}RocketServer{'}'} from &#39;./lib/RocketServer.js&#39;</code></p>
                    <p><code>const PORT = 2083;</code></p>
                    <p><code>let server = new RocketServer(PORT, 10);</code></p>
                    <p><code>'//create group for client to join'</code><br />
                        <code>server.createGroup(&quot;test game 中文測試&quot;);</code></p>
                    <p><code>'//on client connected event'</code><br /><code>server.onConnect((client) =&gt; {
                        'console.log(client);'
                    });</code></p>
                    <p><code>'//on client disconnected event'</code><br /><code>server.onDisconnect((client) =&gt; {
                        'console.log(client);'
                    });</code></p>
                    <p><code>'//on server receive binary message from client event'</code><br /><code>server.onBinaryMessage((client, message) =&gt; {
                        'console.log(message);'
                    });</code></p>
                    <p><code>'//on server receive text message from client event'</code><br /><code>server.onTextMessage((client, message) =&gt; {
                        'console.log(message);'
                    });</code></p>

                    <p><code>'//on client joined group succeed event'</code><br /><code>server.onClientAskJoinGroup((client, group_name) =&gt; {'{'}</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;<code>let group = server.getGroup(group_name);</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;<code>group.broadcast(&quot;client from ip &quot; + client.RemoteIp() + &quot; joined&quot; , client);</code><br /><code>{'}'});</code></p>

                    <p><code>'//on client left group succeed event'</code><br /><code>server.onClientAskLeaveGroup((client, group_name) =&gt; {'{'}</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;<code>let group = server.getGroup(group_name);</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;<code>group.broadcast(&quot;client from ip &quot; + client.RemoteIp() + &quot; left&quot; , client);</code><br /><code>{'}'});</code></p>

                    <p>
                        <code>setInterval(()=&gt;{'{'}</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;<code>let ServerInfo = &quot;connections : &quot; +</code>
                        <code>RocketServer.connections + &quot;\n&quot; +</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code>&quot;recv packets per second : &quot; +</code>
                        <code>RocketServer.lastRecvPacketsPerSec + &quot;\n&quot; +</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code>&quot;send packets per second :&quot; +</code>
                        <code>RocketServer.lastSendPacketsPerSec + &quot;\n&quot; +</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code>&quot;recv packets process time :&quot; +</code>
                        <code>RocketServer.lastRecvRecvProcessTime;</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;<code>console.clear();</code><br /> &nbsp;&nbsp;&nbsp;&nbsp;<code>console.log(ServerInfo);</code><br /><code>{'}'}, 100);</code><br /><br />
                        <br />
                    </p>
                </div>

                <h3 id="client">Client</h3>
                <div className="container" style={{paddingLeft: '2%'}}>
                    <p><code>import {'{'}RocketClient{'}'} from &quot;./lib/RocketClient.js&quot;</code></p>
                    <p><code>let client = new RocketClient(&quot;ws://127.0.0.1:2083&quot;);</code></p>
                    <p><code>'//on connected event'</code><br /><code>client.onConnect((e) =&gt; {'{'}</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>console.log(&quot;Connected&quot;);</code></p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;<code>'//send text message'</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>client.sendMessage(&quot;test text message&quot;);</code></p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;<code>'//send binary message'</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>client.sendMessage(new Uint8Array([68, 31, 31, 31, 31, 31, 51]));</code></p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;<code>'//ask join group'</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>client.joinGroup(&quot;test game 中文測試&quot;);</code></p>
                    <p><code>{'}'});</code></p>
                    <p><code>'//on disconnected event'</code><br /><code>client.onDisconnect((e) =&gt; {'{'}</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>console.log(&quot;Connected&quot;);</code><br /><code>{'}'});</code></p>
                    <p><code>'//on text message'</code><br /><code>client.onTextMessage((message) =&gt; {'{'}</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>console.log(&quot;message&quot;);</code><br /><code>{'}'});</code></p>
                    <p><code>'//on binary message'</code><br /><code>client.onBinaryMessage((message) =&gt; {'{'}</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>console.log(&quot;message&quot;);</code><br /><code>{'}'});</code></p>
                    <p><code>'//on joined group event'</code><br /><code>client.onJoinGroup((groupName, succeed, note)=&gt;{'{'}</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>console.log(&quot;join group &quot; + groupName + &quot; &quot; + note);</code></p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;<code>'//ask leave group'</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>client.leaveGroup(&quot;test game 中文測試&quot;);</code><br /><code>{'}'});</code></p>
                    <p><code>'//on left group event'</code><br /><code>client.onLeaveGroup((groupName, succeed, note)=&gt;{'{'}</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>console.log(&quot;leave group &quot; + groupName + &quot; &quot; + note);</code><br /><code>{'}'});</code></p>
                    <p><code>setInterval(()=&gt;{'{'}</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>if(!client.isConnected())</code><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code>return;</code></p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;<code>'//ask send ping for get latency'</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>client.sendPing();</code></p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;<code>'//get last ping latency'</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>let latency = client.getLatency();</code><br />&nbsp;&nbsp;&nbsp;&nbsp;<code>console.log(&quot;latency : &quot; + latency);</code><br /><code>{'}'}, 100);</code>   </p>
                </div>
                <ul>
                    <li>For more usage details, please download server and client then see the source code of examples.</li>
                </ul>
            </div>
        );
    }
}