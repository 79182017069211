import React from "react";
import md5 from "md5";
import { BaseModal } from "./BaseModal";
import { submitData, dispatchShowMessageEvent, redirectToHome } from "../Header";

export class ResetPassword extends BaseModal {
    constructor(props) {
        super(props);

        this._passwordInput = React.createRef();
    }

    componentDidMount = () => {
        this.showModal();
    }

    onSubmit = async (e) => {
        e.preventDefault();

        const params = new URLSearchParams(window.location.search);
        let token = params.get("token");

        let pass = md5(this._passwordInput.current.value);

        let formData = {
            resettoken: token,
            password: pass,
        };

        let data = await submitData('passwordresetsubmit', formData);

        if (!data.succeed) {
            dispatchShowMessageEvent("Invalid Link, Please Reset Password Again!", true);
        } else {
            setTimeout(() => {
                redirectToHome();
            }, 3000);

            dispatchShowMessageEvent("Reset Password Succeed! Redirecting..");
        }
    }

    onKeyUpRegisterPassword = (e) => {
        e.target.setCustomValidity("");

        let registerPasswordCheckDom = document.getElementById('passwordResetForm_password_check');
        e.target.setCustomValidity('');
        registerPasswordCheckDom.setCustomValidity('');

        // let number = /([0-9])/;
        // let alphabets = /([a-zA-Z])/;
        // let special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
        let password_val = e.target.value;

        if (password_val.length >= 8) {
            if (registerPasswordCheckDom.value !== "" &&
                e.target.value !== registerPasswordCheckDom.value) {
                e.target.setCustomValidity("password not match!");
            }
            else {
                e.target.setCustomValidity("");
            }

            return;
        }

        e.target.setCustomValidity('should be at least 8 characters');
    }

    onKeyUpRegisterPasswordCheck = (e) => {
        e.target.setCustomValidity("");

        let registerPasswordDom = document.getElementById('passwordResetForm_password');
        registerPasswordDom.setCustomValidity('');
        e.target.setCustomValidity('');

        if (registerPasswordDom.value !== e.target.value)
            e.target.setCustomValidity("password not match!");
        else
            e.target.setCustomValidity("");
    }

    render = () => {
        let displayMode = 'block';
        if (!this.state.showModal)
            displayMode = 'none';

        return (
            <div className="modal modalbackground" id="passwordResetFormModal" tabIndex="-1" role="dialog" aria-labelledby="passwordResetFormModal"
                aria-hidden="true"
                style={{ display: displayMode }}>

                <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                    <div className="container h-100">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                                <div className="card" style={{ borderRadius: '15px' }}>

                                    <div className="card-body p-5">
                                        <div className="row">
                                            <div className="col-11"></div>
                                            <div className="col-1">
                                                <button id="passwordResetForm_close" type="button" className="btn-close" onClick={this.closeModal}></button>
                                            </div>
                                        </div>

                                        <h2 className="text-uppercase text-center mb-5">Reset Password</h2>

                                        <form id="resetPasswordForm_form" onSubmit={this.onSubmit} >

                                            {/* <div className="form-outline mb-4">
                                                <input type="email" id="passwordResetForm_email" className="form-control form-control-lg" maxLength="255" disabled />
                                            </div> */}

                                            <div className="form-outline mb-4 form-group">
                                                <input type="password" id="passwordResetForm_password" className="form-control form-control-lg" maxLength="50"
                                                    ref={this._passwordInput} onKeyUp={this.onKeyUpRegisterPassword} required />
                                                <label className="form-label" htmlFor="passwordResetForm_password">Password</label>
                                            </div>

                                            <div className="form-outline mb-4">
                                                <input type="password" id="passwordResetForm_password_check" className="form-control form-control-lg" maxLength="50"
                                                    onKeyUp={this.onKeyUpRegisterPasswordCheck} required />
                                                <label className="form-label" htmlFor="passwordResetForm_password_check">Repeat your password</label>
                                            </div>

                                            <div className="d-flex justify-content-center">
                                                <button type="submit"
                                                    className="btn btn-primary btn-block btn-lg gradient-custom-4 text-body">Reset Password</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}