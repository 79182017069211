import { BaseModal } from "./BaseModal";

export class License extends BaseModal {
    constructor(props) {
        super(props);
        this.state = {
            licenseData: [],
        }
    }

    showLicenses = (data) => {
        this.setState({
            licenseData: data.licenses,
        })

        this.showModal();
    }

    downloadLicense = async (e, md5) => {
        let res = await fetch(process.env.REACT_APP_URL_API + "downloadLicense?md5=" + md5);
        let blobData = await res.blob();
        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        a.download = 'license.dat';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        document.body.removeChild(a);
    }

    render = () => {
        let displayMode = 'block';
        if (!this.state.showModal)
            displayMode = 'none';

        let licenseBody = [];

        for (let i = 0; i < this.state.licenseData.length; i++) {
            let id = 'licenseBody' + (i + 1);
            let ele = this.state.licenseData[i];

            licenseBody.push(
                <tr key={i}>
                    <td hidden> {ele.md5} </td>
                    <td><a href='# '>
                        <i className='i bi-download' style={{ fontSize: '25px' }} onClick={(e) => this.downloadLicense(e, ele.md5)}  ></i>
                    </a>
                    </td>

                    <td>  {id}  </td>
                    <td>  {ele.type}  </td>
                    <td>license.dat</td>
                    <td>  {ele.started_at}  </td>
                    <td>  {ele.expire_at}  </td>
                    <td>  {(ele.type === "BETA" ? "unlimited" : ele.host_limit)}  </td>
                </tr>
            );
        }

        return (
            <div className="modal modalbackground" id="licenseModal" tabIndex="-1" role="dialog" aria-labelledby="licenseModal"
                aria-hidden="true"
                style={{ display: displayMode }}>

                <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                    <div className="container h-100">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-9 col-lg-7 col-xl-6" style={{ width: '100%' }}>
                                <div className="card" style={{ borderRadius: '15px' }}>
                                    <div className="card-body p-5">
                                        <div className="row">
                                            <div className="col-11"></div>
                                            <div className="col-1">
                                                <button id="licenseModal_close" type="button" className="btn-close" onClick={this.closeModal}></button>
                                            </div>
                                        </div>

                                        <div className="panel-body table-responsive">
                                            <table id="licenseModal_table" className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">TYPE</th>
                                                        <th scope="col">File</th>
                                                        <th scope="col">Start Date</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col">Host limited Count</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="licenseModal_table_body">
                                                    {licenseBody}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}