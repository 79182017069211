import util from 'util'
import React, { Component } from 'react';
import { Download } from './modals/Download';
import { License } from './modals/License';
import { LoadingSpinner } from './LoadingSpinner';
import { SignIn } from './modals/SignIn';
import { SignUp } from './modals/SignUp';
import { RequestResetPassword } from './modals/RequestResetPassword';
import { listenShowLoadingEvent, listenCloseAllModalEvent, fetchData, redirectToHome, dispatchShowMessageEvent } from './Header';

export class Navigation extends Component {
    constructor(props) {
        super(props);

        this._loadingSpinner = React.createRef();

        this._downloadModal = React.createRef();
        this._licenseModal = React.createRef();
        this._signInModal = React.createRef();
        this._signUpModal = React.createRef();
        this._requestResetPasswordModal = React.createRef();
    }

    showLicense = async (e) => {
        let data = await fetchData('getLicense', { origin: window.origin })

        if (data.succeed) {
            this._licenseModal.current.showLicenses(data.data);
            return;
        }

        dispatchShowMessageEvent(data.note)
    }

    closeAllModal = () => {
        this._downloadModal.current.closeModal();
        this._licenseModal.current.closeModal();
        this._signInModal.current.closeModal();
        this._signUpModal.current.closeModal();
        this._requestResetPasswordModal.current.closeModal();
    }

    componentDidMount = () => {
        listenShowLoadingEvent((e) => {
            if (e.detail.showLoading)
                this._loadingSpinner.current.show();
            else
                this._loadingSpinner.current.hide();
        });

        listenCloseAllModalEvent((e) => {
            this.closeAllModal();
        });
    }

    onClickSignIn = (e) => {
        this.closeAllModal();
        this._signInModal.current.showModal();
    }

    onClickSignOut = async (e) => {
        this.closeAllModal();

        let data = await fetchData('logout', { origin: window.origin })

        if (data.succeed)
            redirectToHome();
    }

    onShowSignUp = () => {
        this.closeAllModal();
        this._signUpModal.current.showModal();
    }

    onShowSignIn = () => {
        this.closeAllModal();
        this._signInModal.current.showModal();
    }

    onShowRequestResetPassword = () => {
        this.closeAllModal();
        this._requestResetPasswordModal.current.showModal();
    }

    showDownload = (e) => {
        this.closeAllModal();
        this._downloadModal.current.showModal();
    }

    addScript = (id, innerHtml) => {
        if (document.getElementById(id))
            return;

        const script = document.createElement('script');

        script.id = id;
        script.type = 'text/javascript';
        script.innerHTML = innerHtml;
        document.head.appendChild(script);
    }

    isWebview = () => {
        let useragent = window.navigator.userAgent;
        let rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari)', 'Android.*(wv|)'];
        let regex = new RegExp(`(${rules.join('|')})`, 'ig');
        return Boolean(useragent.match(regex));
    }

    openDemonWindow = (e, tagId, relativePath) => {
        e.preventDefault();

        let path = window.origin + process.env.REACT_APP_URL_DEMO_LINK;
        let url = path + relativePath;
        path = path.replaceAll("http://", "");
        path = path.replaceAll("https://", "");

        let wsPath = path + "ws_game/" + relativePath;
        this.addScript(tagId, util.format("var ServerAddr%s = '%s'", tagId, wsPath));

        if (this.isWebview()) {
            let encodeWsPath = encodeURI(wsPath);
            url += util.format('?serveraddr=%s', encodeWsPath);
        }

        let w = window.open(url);
        w.serveraddr = wsPath;
        w.postMessage(wsPath, window.location.origin);
    }

    render = () => {
        let userProfile = <li id="tab_btn_login"><a className="getstarted scrollto" id="btn_show_login" href="# " onClick={this.onClickSignIn}> Sign In </a></li>

        if (this.props.userReducer.value && this.props.userReducer.value !== '') {
            userProfile = <li id="tab_panel_login" className="nav-item dropdown pe-3"><a
                className="nav-link nav-profile d-flex align-items-center pe-0 show"
                href="# " aria-expanded="true">
                <span id="profile_username" className="d-md-block dropdown-toggle">{this.props.userReducer.value}</span>
            </a>
                <ul className="profileDropdown dropdown-menu dropdown-menu-end dropdown-menu-arrow profile show"
                    data-popper-placement="bottom-end">
                    <li>
                        <a className="dropdown-item d-flex align-items-center"
                            id="btn_show_license" href="# " onClick={this.showLicense}>
                            <i className="bi bi-building"></i> <span>Licenses</span>
                        </a>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                        <a className="dropdown-item d-flex align-items-center" href="# " onClick={this.onClickSignOut}>
                            <i className="bi bi-box-arrow-right"></i>
                            <span id="login_btn_logout">Sign Out</span>
                        </a>
                    </li>
                </ul></li>
        }

        let userName = '';
        if (this.props.userReducer.value && this.props.userReducer.value !== '')
            userName = this.props.userReducer.value;

        return (
            <div>
                <nav id="navbar" className="navbar">
                    <ul>
                        <li><a className="nav-link scrollto active" id="btn_home"
                            href="./#home">Home</a></li>
                        <li><a className="nav-link scrollto" id="btn_product"
                            href="./#product">Product</a></li>
                        <li><a className="nav-link scrollto" id="btn_contact"
                            href="./#contact">Contact</a></li>
                        <li className="dropdown">
                            <a href="# ">
                                <span>Live Demo</span> <i className="bi bi-chevron-down"></i>
                            </a>
                            <ul>
                                <li><a href="# " onClick={(e) => this.openDemonWindow(e, "fish", "ExampleFishGame/build/")}>ExampleFishingGame</a></li>
                                <li><a href="# " onClick={(e) => this.openDemonWindow(e, "rawmessage", "ExampleRawMessage/")}>ExampleRawMessage</a></li>
                            </ul>
                        </li>
                        <li><a className="nav-link scrollto" href="# " id="btn_show_download" onClick={this.showDownload}>Download</a></li>
                        <li><a className="nav-link scrollto" href="./tutorial" id="btn_tutorial">Tutorial</a></li>

                        {userProfile}
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle"></i>
                </nav>

                <License ref={this._licenseModal} />
                <Download ref={this._downloadModal} userName={userName}
                    setShowSignIn={this.onShowSignIn} setShowLicense={this.showLicense} />

                <LoadingSpinner ref={this._loadingSpinner} />
                <SignIn ref={this._signInModal} setShowSignUp={this.onShowSignUp} setShowRequestResetPassword={this.onShowRequestResetPassword} />
                <SignUp ref={this._signUpModal} setShowSignIn={this.onShowSignIn} />
                <RequestResetPassword ref={this._requestResetPasswordModal} />
            </div>
        );
    }
}
