import { BaseModal } from "./BaseModal";

export class Message extends BaseModal {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
        }
    }
    showMessage = (text, error = undefined) => {
        this.setState({
            message: text,
            showError: (error !== undefined),
        })

        this.showModal();
    }

    render = () => {
        let displayMode = 'block';
        if (!this.state.showModal)
            displayMode = 'none';

        let checkMark = <span id="messageModal_check" className="bi bi-check" style={{ fontSize: '200px', color: 'rgb(0, 128, 55)' }}></span>;

        if (this.state.showError)
            checkMark = <span id="messageModal_error" className="bi bi bi-x" style={{ fontSize: '200px', color: 'rgb(200, 0, 0)' }}></span>

        return (
            <div className="modal modalbackground" id="messageModal" tabIndex="-1" role="dialog" aria-labelledby="messageModal"
                aria-hidden="true"
                style={{ display: displayMode }}>

                <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                    <div className="container h-100">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                                <div className="card" style={{ borderRadius: '15px' }}>

                                    <div className="card-body p-5">
                                        <div className="row">
                                            <div className="col-11"></div>
                                            <div className="col-1">
                                                <button id="messageModal_close" type="button" className="btn-close" onClick={this.closeModal}></button>
                                            </div>
                                        </div>

                                        <div className="row text-center ">
                                            {checkMark}

                                            <h2 id="messageModal_text" className="text-uppercase text-center mb-5">
                                                {this.state.message}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}