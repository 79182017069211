import React from "react";
import md5 from "md5";
import { BaseModal } from "./BaseModal";
import { redirectToHome, submitData, dispatchShowMessageEvent } from "../Header";

export class SignIn extends BaseModal {
    constructor(props) {
        super(props);

        this._emailInput = React.createRef();
        this._passwordInput = React.createRef();
    }

    onSubmit = async (e) => {
        e.preventDefault();

        let pass = md5(this._passwordInput.current.value);

        let formData = {
            email: this._emailInput.current.value,
            password: pass,
        };

        let data = await submitData('login', formData);

        if (!data.succeed) {
            let inputField = null;
            switch (data.data.invalidField) {
                case "email":
                    inputField = this._emailInput;
                    break;
                case "password":
                    inputField = this._passwordInput;
                    break;
                default:
                    dispatchShowMessageEvent("something went wrong, please contact us!", true);
                    break
            }

            inputField.current.setCustomValidity(data.note);
            inputField.current.reportValidity();
        } else {
            redirectToHome();
        }
    }

    onShowSignUp = (e) => {
        this.props.setShowSignUp();
    }

    onShowRequestResetPassword = (e) => {
        this.props.setShowRequestResetPassword();
    }

    onKeyUpPassword = (e) => {
        e.target.setCustomValidity("");
    }

    render = () => {
        let displayMode = 'block';
        if (!this.state.showModal)
            displayMode = 'none';

        return (
            <div className="modal modalbackground" id="loginModal" tabIndex="-1"
                role="dialog" aria-labelledby="loginModal" aria-hidden="true"
                style={{ display: displayMode }}>

                <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                    <div className="container h-100">
                        <div
                            className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                                <div className="card" style={{ borderRadius: '15px' }}>
                                    <div className="card-body p-5">
                                        <div className="row">
                                            <div className="col-11"></div>
                                            <div className="col-1">
                                                <button id="loginModal_close" type="button" className="btn-close" onClick={this.closeModal}></button>
                                            </div>
                                        </div>
                                        <div style={{ height: '60vh', overflowY: 'auto' }}>
                                            <h2 className="text-uppercase text-center mb-5">Sign In</h2>
                                            <form id="login_form" onSubmit={this.onSubmit}>
                                                <div className="form-outline mb-4">
                                                    <input type="email" id="login_email"
                                                        placeholder="please enter your email"
                                                        className="form-control form-control-lg" maxLength="255"
                                                        ref={this._emailInput} required />

                                                    <label className="form-label" htmlFor="login_email">Email</label>
                                                </div>
                                                <div className="form-outline mb-4">
                                                    <input type="password" id="login_password"
                                                        placeholder="please enter your password"
                                                        className="form-control form-control-lg" maxLength="50"
                                                        ref={this._passwordInput}
                                                        onKeyUp={this.onKeyUpPassword} required />

                                                    <label className="form-label" htmlFor="login_password">Password</label>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <button type="submit"
                                                        className="btn btn-primary btn-block btn-lg gradient-custom-4 text-body">Sign
                                                        In</button>
                                                </div>
                                                <p className="text-center text-muted mt-5 mb-0">
                                                    Don't have an account?
                                                    <a href="# " className="fw-bold text-body" onClick={this.onShowSignUp}>
                                                        <u>Register here</u>
                                                    </a>
                                                </p>
                                                <p className="text-center text-muted mt-5 mb-0">
                                                    <a href="# " className="fw-bold text-body" onClick={this.onShowRequestResetPassword}>
                                                        <u>Reset password here</u>
                                                    </a>
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}