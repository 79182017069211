import { Component } from "react";

export class Home extends Component {    

    showDownload = (e) => {
        this.props.onShowDownload();
    }

    render = () => {
        return (
            // ======= home  Section =======
            < section id="home" className="d-flex align-items-center" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                            <h1>High Performance WebSocket Server for your NodeJS service</h1>
                            <h2>a lightweight, easy to use but high performance WebSocket Server, now support for NodeJS</h2>
                            <div className="d-flex justify-content-center justify-content-lg-start">
                                <a href="# " className="btn-get-started scrollto" onClick={this.showDownload} >Get Started</a>
                                {/* <!--             <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="glightbox btn-watch-video"><i class="bi bi-play-circle"></i><span>Watch Video</span></a> --> */}
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 home-img" data-aos="zoom-in" data-aos-delay="200">
                            <img src="./resources/assets/img/main.png" className="img-fluid animated" alt="" />
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}