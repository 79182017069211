import React from "react";
import { BaseModal } from "./BaseModal";
import { submitData, dispatchShowMessageEvent } from "../Header";

export class RequestResetPassword extends BaseModal {
    constructor(props) {
        super(props);

        this._emailInput = React.createRef();
    }

    onSubmit = async (e) => {
        e.preventDefault();
        
        let formData = {
            email: this._emailInput.current.value,
        };

        let data = await submitData('resetpassword', formData);

        if (!data.succeed) {
            this._emailInput.current.setCustomValidity(data.note);
            this._emailInput.current.reportValidity();
        } else {
            dispatchShowMessageEvent("Reset Password Request Succeed, Please check your email");
        }
    }

    render = () => {
        let displayMode = 'block';
        if (!this.state.showModal)
            displayMode = 'none';

        return (
            <div className="modal modalbackground" id="passwordResetModal" tabIndex="-1" role="dialog" aria-labelledby="passwordResetModal"
                aria-hidden="true"
                style={{ display: displayMode }}>

                <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                    <div className="container h-100">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                                <div className="card" style={{ borderRadius: '15px' }}>

                                    <div className="card-body p-5">
                                        <div className="row">
                                            <div className="col-11"></div>
                                            <div className="col-1">
                                                <button id="passwordReset_close" type="button" className="btn-close" onClick={this.closeModal}></button>
                                            </div>
                                        </div>

                                        <h2 className="text-uppercase text-center mb-5">Reset Password</h2>

                                        <form id="passwordReset_form" onSubmit={this.onSubmit}>
                                            <div className="form-outline mb-4">
                                                <input type="email" id="passwordReset_email" placeholder="please enter your email"
                                                    className="form-control form-control-lg" maxLength="255"
                                                    ref={this._emailInput} required />

                                                <label className="form-label" htmlFor="passwordReset_email">Email</label>
                                            </div>

                                            <div className="d-flex justify-content-center">
                                                <button type="submit"
                                                    className="btn btn-primary btn-block btn-lg gradient-custom-4 text-body">Reset Password</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}