import { Component } from "react";
import { Home } from "./sections/Home";
import { Product } from "./sections/Product";
import { Pricing } from "./sections/Pricing";
import { Contact } from "./sections/Contact";

export class Main extends Component {
    onShowDownload = ()=>{
        this.props.onShowDownload();
    }

    render = () => {
        return (
            <div>
                <Home onShowDownload = {this.onShowDownload}/>

                < main id="main">
                    <Product />
                    <Pricing />
                    <Contact />
                </main>
            </div >
        );
    }
}