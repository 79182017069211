import { BaseModal } from "./BaseModal";

export class PrivacyPolicy extends BaseModal {
    render = () => {
        let displayMode = 'block';
        if (!this.state.showModal)
            displayMode = 'none';

        return (
            <div className="modal modalbackground" id="privacyPolicyModal" tabIndex="-1"
                role="dialog" aria-labelledby="privacyPolicyModal" aria-hidden="true"
                style={{ display: displayMode }}>

                <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                    <div className="container h-100">
                        <div
                            className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-9 col-lg-7 col-xl-6" style={{ width: '100%' }}>
                                <div className="card" style={{ borderRadius: '15px' }}>
                                    <div className="card-body p-5">
                                        <div className="row">
                                            <div className="col-11"></div>
                                            <div className="col-1">
                                                <button id="privacyPolicyModal_close" type="button"
                                                    className="btn-close" onClick={this.closeModal}></button>
                                            </div>
                                        </div>
                                        <div style={{ height: '60vh', overflowY: 'auto' }}>
                                            <div data-custom-class="body">
                                                <div>
                                                    <strong><span style={{ fontSize: '26px' }}><span
                                                        data-custom-class="title">PRIVACY POLICY</span></span></strong>
                                                </div>
                                                <div>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: '1.5' }}>
                                                    <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                            data-custom-class="body_text">This privacy notice for
                                                            RocketServer  ("<strong>Company</strong> ," "<strong>we</strong>,"
                                                            "<strong>us</strong>," or "<strong>our</strong>"
                                                        </span><span data-custom-class="body_text">), describes how
                                                            and why we might collect, store, use, and/or share ("<strong>process</strong>") your
                                                            information when you use our services ("<strong>Services</strong>"), such as when
                                                            you:
                                                        </span></span></span>
                                                    <div>
                                                        <span
                                                            style={{ fontSize: '15px' }}><span
                                                                style={{ fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                        data-custom-class="body_text"><span
                                                                            style={{ color: 'rgb(89, 89, 89)' }}><span
                                                                                data-custom-class="body_text"></span></span></span></span></span></span>
                                                        <div style={{ lineHeight: '1.5' }}>
                                                            <span style={{ fontSize: '15px' }}><span
                                                                style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                    data-custom-class="body_text"><span
                                                                        style={{ color: 'rgb(89, 89, 89)' }}><span
                                                                            data-custom-class="body_text"></span></span></span></span></span>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                        data-custom-class="body_text"><strong>Questions
                                                                            or concerns?&nbsp;</strong>Reading this privacy notice will help
                                                                        you understand your privacy rights and choices. If you
                                                                        do not agree with our policies and practices, please do
                                                                        not use our Services. If you still have any questions or
                                                                        concerns, please contact us at rocketserverteam@gmail.com.</span></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <strong><span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="heading_1">SUMMARY OF KEY
                                                                    POINTS</span></span></strong>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="body_text"><strong><em>This
                                                                        summary provides key points from our privacy notice,
                                                                        but you can find out more details about any of these
                                                                        topics by clicking the link following each key point or
                                                                        by using our&nbsp;</em></strong></span></span>
                                                                <a data-custom-class="link" href="#toc">
                                                                    <span style={{ fontSize: '15px' }}><span
                                                                        data-custom-class="body_text"><strong><em>table
                                                                            of contents</em></strong></span></span>
                                                                </a>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="body_text"><strong><em>&nbsp;below
                                                                        to find the section you are looking for.</em></strong></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="body_text"><strong>What
                                                                        personal information do we process?</strong> When you visit, use,
                                                                    or navigate our Services, we may process personal
                                                                    information depending on how you interact with the
                                                                    Services, the choices you make, and the products and
                                                                    features you use. Learn more about&nbsp;</span></span>
                                                                <a data-custom-class="link" href="#personalinfo">
                                                                    <span style={{ fontSize: '15px' }}><span
                                                                        data-custom-class="body_text">personal information
                                                                        you disclose to us</span></span>
                                                                </a>
                                                                <span data-custom-class="body_text">.</span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="body_text"><strong>Do
                                                                        we process any sensitive personal information?</strong> We do not process
                                                                    sensitive personal information.</span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="body_text"><strong>How
                                                                        do we process your information?</strong> We process your
                                                                    information to provide, improve, and administer our
                                                                    Services, communicate with you, for security and fraud
                                                                    prevention, and to comply with law. We may also process
                                                                    your information for other purposes with your consent. We
                                                                    process your information only when we have a valid legal
                                                                    reason to do so. Learn more about&nbsp;</span></span>
                                                                <a data-custom-class="link" href="#infouse">
                                                                    <span style={{ fontSize: '15px' }}><span
                                                                        data-custom-class="body_text">how we process your
                                                                        information</span></span>
                                                                </a>
                                                                <span data-custom-class="body_text">.</span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="body_text"><strong>In
                                                                        what situations and with which parties do we share
                                                                        personal information?
                                                                    </strong> We may share information in specific situations and with
                                                                    specific third
                                                                    parties. Learn more about&nbsp;</span></span>
                                                                <a data-custom-class="link" href="#whoshare">
                                                                    <span style={{ fontSize: '15px' }}><span
                                                                        data-custom-class="body_text">when and with whom
                                                                        we share your personal information</span></span>
                                                                </a>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="body_text">.</span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div id="toc" style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                        style={{ color: 'rgb(0, 0, 0)' }}><strong><span
                                                                            data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></span></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)' }}><a
                                                                        data-custom-class="link" href="#infocollect">1. WHAT
                                                                        INFORMATION DO WE COLLECT?</a></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)' }}><a
                                                                        data-custom-class="link" href="#infouse">2. HOW DO
                                                                        WE PROCESS YOUR INFORMATION?</a></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)' }}><a
                                                                        data-custom-class="link" href="#whoshare">3. WHEN
                                                                        AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)' }}><a
                                                                        data-custom-class="link" href="#cookies">4. DO WE
                                                                        USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)' }}><a
                                                                        data-custom-class="link" href="#inforetain">5. HOW
                                                                        LONG DO WE KEEP YOUR INFORMATION?</a></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)' }}><a
                                                                        data-custom-class="link" href="#policyupdates">6. DO
                                                                        WE MAKE UPDATES TO THIS NOTICE?</a></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div id="infocollect" style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span
                                                                                    data-custom-class="heading_1">1. WHAT
                                                                                    INFORMATION DO WE COLLECT?</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div id="personalinfo" style={{ lineHeight: '1.5' }}>
                                                                <span data-custom-class="heading_2"
                                                                    style={{ color: 'rgb(0, 0, 0)' }}><span
                                                                        style={{ fontSize: '15px' }}><strong>Personal
                                                                            information you disclose to us</strong></span></span>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                    <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                            data-custom-class="body_text"><span
                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                            Short:</em></strong></span></span></span></span><span data-custom-class="body_text"><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                        data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We
                                                                                                            collect personal information that you provide to
                                                                                                            us.</em></span></span></span></span></span></span>
                                                                </div>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        data-custom-class="body_text">We collect personal
                                                                        information that you voluntarily provide to us when you
                                                                        <span style={{ fontSize: '15px' }}></span>register on the
                                                                        Services,&nbsp;
                                                                    </span><span style={{ fontSize: '15px' }}><span
                                                                        data-custom-class="body_text"><span
                                                                            style={{ fontSize: '15px' }}></span></span><span
                                                                                data-custom-class="body_text">express an interest
                                                                            in obtaining information about us or our products and
                                                                            Services, when you participate in activities on the
                                                                            Services, or otherwise when you contact us.</span></span></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        data-custom-class="body_text"><span
                                                                            style={{ fontSize: '15px' }}></span></span></span></span>
                                                            </div>
                                                            <div id="sensitiveinfo" style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="body_text"><strong>Sensitive
                                                                        Information.</strong> We do
                                                                    not process sensitive information.</span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ fontSize: '15px' }}><span
                                                                    data-custom-class="body_text"></span></span><span
                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                data-custom-class="body_text"><span
                                                                                    style={{ fontSize: '15px' }}><span
                                                                                        data-custom-class="body_text"> </span></span></span></span> </span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        data-custom-class="body_text">All personal
                                                                        information that you provide to us must be true,
                                                                        complete, and accurate, and you must notify us of any
                                                                        changes to such personal information.</span></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        data-custom-class="body_text"></span></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span data-custom-class="heading_2"
                                                                    style={{ color: 'rgb(0, 0, 0)' }}><span
                                                                        style={{ fontSize: '15px' }}><strong>Information
                                                                            automatically collected</strong></span></span>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                    <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                            data-custom-class="body_text"><span
                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                            Short:</em></strong></span></span></span></span><span data-custom-class="body_text"><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                        data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>Some
                                                                                                            information — such as your Internet Protocol (IP)
                                                                                                            address and/or browser and device characteristics —
                                                                                                            is collected automatically when you visit our
                                                                                                            Services.</em></span></span></span></span></span></span>
                                                                </div>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        data-custom-class="body_text">We automatically
                                                                        collect certain information when you visit, use, or
                                                                        navigate the Services. This information does not reveal
                                                                        your specific identity (like your name or contact
                                                                        information) but may include device and usage
                                                                        information, such as your IP address, browser and device
                                                                        characteristics, operating system, language preferences,
                                                                        referring URLs, device name, country, location,
                                                                        information about how and when you use our Services, and
                                                                        other technical information. This information is
                                                                        primarily needed to maintain the security and operation
                                                                        of our Services, and for our internal analytics and
                                                                        reporting purposes.</span></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        data-custom-class="body_text"></span></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        data-custom-class="body_text">Like many
                                                                        businesses, we also collect information through cookies
                                                                        and similar technologies.

                                                                    </span></span></span>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        data-custom-class="body_text"> <span
                                                                            data-custom-class="body_text"></span></span><span
                                                                                data-custom-class="body_text"> </span><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                            data-custom-class="body_text"><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    data-custom-class="body_text"> </span></span></span></span></span></span></span><span
                                                                                                        style={{ fontSize: '15px' }}><span
                                                                                                            data-custom-class="body_text"></span></span>
                                                            </div>
                                                            <div id="infouse" style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span
                                                                                    data-custom-class="heading_1">2. HOW DO WE
                                                                                    PROCESS YOUR INFORMATION?</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                    <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                            data-custom-class="body_text"><span
                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                            Short:&nbsp;</em></strong><em>We process your information
                                                                                                to provide, improve, and administer our Services,
                                                                                                communicate with you, for security and fraud
                                                                                                prevention, and to comply with law. We may also
                                                                                                process your information for other purposes with
                                                                                                your consent.</em></span></span></span></span></span></span>
                                                                </div>
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <br />
                                                            </div>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        data-custom-class="body_text"><strong>We
                                                                            process your personal information for a variety of
                                                                            reasons, depending on how you interact with our
                                                                            Services</strong> </span></span></span>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                    <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                            data-custom-class="body_text"></span></span></span>
                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                        <span
                                                                            style={{ fontSize: '15px' }}><span
                                                                                data-custom-class="body_text"></span></span>
                                                                        <p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                                                            <span
                                                                                style={{ fontSize: '15px' }}></span>
                                                                        </p>
                                                                        <p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                                                            <span
                                                                                style={{ fontSize: '15px' }}></span>
                                                                        </p>
                                                                        <p style={{ fontSize: '15px', lineHeight: '1.5' }}>

                                                                        </p>
                                                                        <p style={{ fontSize: '15px', lineHeight: '1.5' }}>

                                                                        </p>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                            <span
                                                                                style={{ fontSize: '15px' }}><span
                                                                                    data-custom-class="body_text"></span></span>
                                                                            <span
                                                                                style={{ fontSize: '15px' }}><span
                                                                                    data-custom-class="body_text"></span></span>
                                                                            <span
                                                                                style={{ fontSize: '15px' }}><span
                                                                                    data-custom-class="body_text"></span></span>
                                                                            <span
                                                                                style={{ fontSize: '15px' }}><span
                                                                                    data-custom-class="body_text"></span></span>
                                                                        </div>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                            <br />
                                                                        </div>
                                                                        <div id="whoshare" style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                            id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span
                                                                                                data-custom-class="heading_1">3. WHEN AND
                                                                                                WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong></span></span></span></span></span>
                                                                        </div>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                            <br />
                                                                        </div>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                    data-custom-class="body_text"><strong><em>In
                                                                                        Short:</em></strong><em>&nbsp;We may share information in
                                                                                            specific situations described in this section and/or
                                                                                            with the following third
                                                                                            parties.
                                                                                    </em></span></span></span>
                                                                        </div>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                    data-custom-class="body_text"></span></span></span>
                                                                        </div>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                            <br />
                                                                        </div>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px' }}><span
                                                                                data-custom-class="body_text">We may need to share your
                                                                                personal information in the following situations:
                                                                            </span></span>
                                                                        </div>
                                                                        <ul>
                                                                            <li style={{ lineHeight: '1.5' }}><span
                                                                                style={{ fontSize: '15px' }}><span
                                                                                    data-custom-class="body_text"><strong>Business
                                                                                        Transfers.</strong> We may share or transfer your information
                                                                                    in connection with, or during negotiations of, any
                                                                                    merger, sale of company assets, financing, or
                                                                                    acquisition of all or a portion of our business to
                                                                                    another company.</span></span></li>
                                                                        </ul>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px' }}><span
                                                                                data-custom-class="body_text"></span></span>
                                                                            <div style={{ lineHeight: '1.5' }}>
                                                                                <span style={{ fontSize: '15px' }}> <span
                                                                                    data-custom-class="body_text"></span></span>
                                                                            </div>
                                                                            <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span
                                                                                    style={{ fontSize: '15px' }}><span
                                                                                        data-custom-class="body_text"><strong>Affiliates.&nbsp;</strong>We
                                                                                        may share your information with our affiliates, in
                                                                                        which case we will require those affiliates to honor this
                                                                                        privacy notice. Affiliates include our parent
                                                                                        company and any subsidiaries, joint venture
                                                                                        partners, or other companies that we control or that
                                                                                        are under common control with us.</span>  <span
                                                                                            data-custom-class="body_text"></span></span></li>
                                                                            </ul>
                                                                            <div style={{ lineHeight: '1.5' }}>
                                                                                <span
                                                                                    style={{ fontSize: '15px' }}><span
                                                                                        data-custom-class="body_text"></span></span>
                                                                            </div>
                                                                            <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style={{ fontSize: '15px' }}><strong>Business
                                                                                            Partners.</strong> We may share your information with our
                                                                                        business partners to offer you certain products,
                                                                                        services, or promotions.</span></span>  <span
                                                                                            style={{ fontSize: '15px' }}><span
                                                                                                data-custom-class="body_text"></span></span></li>
                                                                            </ul>
                                                                            <div style={{ lineHeight: '1.5' }}>
                                                                                <span
                                                                                    style={{ fontSize: '15px' }}><span
                                                                                        data-custom-class="body_text"></span></span>
                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                    <span
                                                                                        style={{ fontSize: '15px' }}><span
                                                                                            data-custom-class="body_text"></span></span>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <span
                                                                                            style={{ fontSize: '15px' }}><span
                                                                                                data-custom-class="body_text"></span></span>
                                                                                        <span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ fontSize: '15px' }}><span
                                                                                                    style={{ color: 'rgb(89, 89, 89)' }}><span
                                                                                                        style={{ fontSize: '15px' }}><span
                                                                                                            style={{ color: 'rgb(89, 89, 89)' }}> <span
                                                                                                                data-custom-class="heading_1"></span></span></span></span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div id="cookies" style={{ lineHeight: '1.5' }}>
                                                                                        <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                        id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span
                                                                                                            data-custom-class="heading_1">4. DO WE
                                                                                                            USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong></span></span></span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    data-custom-class="body_text"><strong><em>In
                                                                                                        Short:</em></strong><em>&nbsp;We may use cookies and
                                                                                                            other tracking technologies to collect and store
                                                                                                            your information.</em></span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    data-custom-class="body_text">We may use
                                                                                                    cookies and similar tracking technologies (like
                                                                                                    web beacons and pixels) to access or store
                                                                                                    information. Specific information about how we use
                                                                                                    such technologies and how you can refuse certain
                                                                                                    cookies is set out in our Cookie Notice<span
                                                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                            data-custom-class="body_text">.</span><span
                                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                                    style={{ fontSize: '15px' }}><span
                                                                                                                        style={{ color: 'rgb(89, 89, 89)' }}><span
                                                                                                                            style={{ fontSize: '15px' }}><span
                                                                                                                                style={{ color: 'rgb(89, 89, 89)' }}><span
                                                                                                                                    data-custom-class="body_text"></span></span></span></span></span></span></span>
                                                                                                </span><span data-custom-class="body_text"><span
                                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                        style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                                style={{ fontSize: '15px' }}><span
                                                                                                                    style={{ color: 'rgb(89, 89, 89)' }}><span
                                                                                                                        style={{ fontSize: '15px' }}><span
                                                                                                                            style={{ color: 'rgb(89, 89, 89)' }}><span
                                                                                                                                data-custom-class="body_text"></span></span></span></span></span></span></span></span></span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div id="inforetain" style={{ lineHeight: '1.5' }}>
                                                                                        <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                        id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span
                                                                                                            data-custom-class="heading_1">5. HOW
                                                                                                            LONG DO WE KEEP YOUR INFORMATION?</span></strong></span></span></span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    data-custom-class="body_text"><strong><em>In
                                                                                                        Short:&nbsp;</em></strong><em>We keep your information
                                                                                                            for as long as necessary to fulfill the
                                                                                                            purposes outlined in this privacy notice unless
                                                                                                            otherwise required by law.
                                                                                                    </em></span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    data-custom-class="body_text">We will only
                                                                                                    keep your personal information for as long as it
                                                                                                    is necessary for the purposes set out in this
                                                                                                    privacy notice, unless a longer retention period
                                                                                                    is required or permitted by law (such as tax,
                                                                                                    accounting, or other legal requirements).
                                                                                                </span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    data-custom-class="body_text">When we have
                                                                                                    no ongoing legitimate business need to process
                                                                                                    your personal information, we will either delete
                                                                                                    or anonymize such
                                                                                                    information, or, if this is not possible (for
                                                                                                    example, because your personal information has
                                                                                                    been stored in backup archives), then we will
                                                                                                    securely store your personal information and
                                                                                                    isolate it from any further processing until
                                                                                                    deletion is possible.<span
                                                                                                        style={{ color: 'rgb(89, 89, 89)' }}></span><span
                                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                                data-custom-class="body_text"></span></span>
                                                                                                </span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div id="policyupdates" style={{ lineHeight: '1.5' }}>
                                                                                        <span style={{ color: 'rgb(127, 127, 127)' }}><span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                        id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span
                                                                                                            data-custom-class="heading_1">6. DO WE
                                                                                                            MAKE UPDATES TO THIS NOTICE?</span></strong></span></span></span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    data-custom-class="body_text"><em><strong>In
                                                                                                        Short:&nbsp;</strong>Yes, we will update this notice as
                                                                                                        necessary to stay compliant with relevant laws.</em></span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <span
                                                                                            style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span
                                                                                                    data-custom-class="body_text">We may update
                                                                                                    this privacy notice from time to time. If we make
                                                                                                    material changes to this privacy notice, we may
                                                                                                    notify you either by prominently posting a notice
                                                                                                    of such changes or by directly sending you a
                                                                                                    notification. We encourage you to review this
                                                                                                    privacy notice frequently to be informed of how we
                                                                                                    are protecting your information. </span></span></span>
                                                                                    </div>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <br />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}