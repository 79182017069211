import { Component } from "react";

export class Pricing extends Component {
    render = () => {
        return (
            <section id="pricing" className="pricing">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Pricing</h2>

                        <h1>
                            <p>Now it is free on Beta Test Period! Getting started today!</p>
                        </h1>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
                            <div className="box">
                                <h3>Basic Plan</h3>
                                <h4>
                                    <sup>$</sup>
                                    <del>50. USD</del>
                                    <span>per month for single App</span>
                                </h4>
                                <ul>
                                    <li><i className="bx bx-check"></i> High performance</li>
                                    <li><i className="bx bx-check"></i> Easy to use</li>
                                    <li><i className="bx bx-check"></i> customize your Apps count as you need</li>
                                    <li><i className="bx bx-check"></i> subscribe on demand monthly, cancel subscription in anytime</li>
                                </ul>

                                <button type="button" className="buy-btn" disabled>Get Started</button>
                            </div>
                        </div>

                        <div className="col-lg-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
                            <div className="box featured">
                                <h3>Business Plan</h3>
                                <h4>
                                    <sup>$</sup>
                                    <del>1000. USD</del>
                                    <span>max to 30 Apps per month</span>
                                </h4>
                                <ul>
                                    <li><i className="bx bx-check"></i> High performance</li>
                                    <li><i className="bx bx-check"></i> Easy to use</li>
                                    <li><i className="bx bx-check"></i> up to 30 Apps running maximum</li>
                                    <li><i className="bx bx-check"></i> subscribe on demand monthly, cancel subscription in anytime</li>
                                </ul>

                                <button type="button" className="buy-btn" disabled>Get Started</button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}